<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['UserManagementMain', 'UserManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.users_parent') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="filter__width">
											<div class="row">
												<div class="col-lg-4">
													<div class="analytic__filter--item">
														<label class="search__label">
															<input
																type="text"
																value=""
																name="filter_search"
																v-model="filters.search"
																:placeholder="$t('placeholder.search')"
															/>
															<button>
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
															</button>
														</label>
													</div>
												</div>
												<div class="col-lg-4" v-if="roleList">
													<div class="analytic__filter--item">
														<multiselect
															class="select__status"
															:value="filters.roles"
															:options="roleList"
															:clearOnSelect="false"
															:clear-on-select="false"
															:placeholder="$t('placeholder.choose')"
															@input="filters.roles = $event"
														>
															<template slot="option" slot-scope="{ option }">{{
																$t('roles.' + option.description)
															}}</template>
															<template slot="singleLabel" slot-scope="{ option }">{{
																$t('roles.' + option.description)
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
												<div class="col-lg-4" v-if="regionList">
													<div class="analytic__filter--item">
														<multiselect
															class="select__status"
															:value="filters.region"
															:options="regionList"
															label="name"
															:clearOnSelect="false"
															:clear-on-select="false"
															:placeholder="$t('placeholder.choose')"
															@input="filters.region = $event"
														>
															<template slot="option" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
												<div class="col-lg-4">
													<div class="analytic__filter--item">
														<multiselect
															class="select__status"
															:value="filters.status"
															:options="orderList"
															label="name"
															:clearOnSelect="false"
															:clear-on-select="false"
															:placeholder="$t('placeholder.choose')"
															@input="filters.status = $event"
														>
															<template slot="singleLabel" slot-scope="{ option }">{{
																option.name
															}}</template>
															<template slot="noResult">{{ $t('components.not_found') }}</template>
														</multiselect>
													</div>
												</div>
												<div class="col-lg-3">
													<!-- ml-auto -->
													<div class="analytic__filter--item">
														<router-link
															v-if="$store.getters.userPermission(['change_user'])"
															:to="'/' + $i18n.locale + '/account/users/create'"
															class="btn--link btn--link--plus"
														>
															{{ $t('titles.registration_user') }}
															<img src="../../../assets/img/icon-plus.svg" />
														</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.name="{ item }">
											{{ item.first_name }} {{ item.last_name }} {{ item.middle_name }}
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.role_id="{ item }">
											{{ $t('roles.' + item.role.description) }}
										</template>

										<template v-slot:item.regions_info="{ item }">
											<div>
												<div
													v-for="item in item.regions_info"
													:key="item.value"
													style="font-size: 12px"
												>
													{{ $t('regions.' + item.value) }}
												</div>
											</div>
										</template>

										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.is_deleted == 0,
													'tb__status--error': item.is_deleted == 1,
												}"
											>
												{{ item.is_deleted == 0 ? $t('status.active') : $t('status.blocked') }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<router-link
												v-if="$store.getters.userPermission(['read_user'])"
												:to="'/' + $i18n.locale + '/account/users/' + item.id"
												class="tb__table--btn tb__table--view"
												v-tooltip.top-center="{
													content: $t('tooltip.look'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<router-link
												v-if="$store.getters.userPermission(['change_user'])"
												:to="'/' + $i18n.locale + '/account/users/' + item.id + '/edit'"
												class="tb__table--btn tb__table--edit"
												v-tooltip.top-center="{
													content: $t('tooltip.edit'),
													class: ['tooltip__btn'],
												}"
											>
												<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9 15h6.75M12.375 2.625a1.591 1.591 0 112.25 2.25L5.25 14.25l-3 .75.75-3 9.375-9.375z" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</router-link>
											<a
												class="tb__table--btn tb__table--view"
												v-if="
													$store.getters.userPermission(['delete_user']) && item.is_deleted != 0
												"
												@click="activeElement(item.id)"
												v-tooltip.top-center="{ content: 'Активировать', class: ['tooltip__btn'] }"
											>
												<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 100 16.666z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.5 10l1.667 1.667L12.5 8.333" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</a>
											<a
												v-if="
													$store.getters.userPermission(['delete_user']) && item.is_deleted == 0
												"
												class="tb__table--btn tb__table--delete"
												@click="$modal.show('modal_delete'), (blockElemet = item)"
												v-tooltip.top-center="{ content: 'Блокировать', class: ['tooltip__btn'] }"
											>
												<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</a>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>

									<modal
										class="modal__block modal__block--middle modal__block--delete"
										name="modal_delete"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_delete')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														Вы уверены что хотите заблокировать пользователя<br />
														<strong>
															{{ blockElemet ? blockElemet.first_name : '' }}
															{{ blockElemet ? blockElemet.last_name : '' }}
															{{ blockElemet ? blockElemet.middle_name : '' }}
														</strong>
													</div>
												</div>

												<form method="POST" @submit.prevent="blockElement">
													<div class="modal__content">
														<div class="modal__form">
															<div class="form__line">
																<div class="form__line--right">
																	<div
																		class="form__line--input"
																		:class="[
																			errorMessage.messages && errorMessage.messages.comment
																				? 'input--error'
																				: '',
																		]"
																	>
																		<textarea
																			class="input-linck"
																			required=""
																			name="comment"
																			placeholder="Причина отклонения"
																		></textarea>
																	</div>
																	<div
																		class="input-required"
																		v-if="errorMessage.messages && errorMessage.messages.comment"
																	>
																		<p
																			v-for="(massage, massageInd) in errorMessage.messages.comment"
																			:key="massageInd"
																		>
																			{{ massage }}
																		</p>
																	</div>
																</div>
															</div>

															<div class="block__row">
																<div class="block__row--left">
																	<button class="block__item" v-if="blockElemet">
																		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																		{{ $t('button.deactivate') }}
																	</button>
																</div>
																<div class="block__row--right">
																	<a
																		class="block__item block__item--blue"
																		@click="$modal.hide('modal_delete')"
																	>
																		{{ $t('button.cancel') }}
																	</a>
																</div>
															</div>
															<div
																class="form__block--line form__messages"
																v-if="message.status"
																:class="{
																	'form__messages--error': message.status != 200,
																	'form__messages--success': message.status == 200,
																}"
															>
																<label class="form__block--input">
																	<span class="form__block--title">
																		{{ message.text }}
																	</span>
																</label>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</modal>
									<modal
										class="modal__block modal__block--middle modal__block--success"
										name="modal_success"
										:width="550"
										:min-width="550"
										height="auto"
									>
										<div class="modal__block--wrapper">
											<div class="modal__block--close" @click="$modal.hide('modal_success')">
												<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22.5 7.5l-15 15M7.5 7.5l15 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
											</div>
											<div class="modal__wrapper">
												<div class="modal__header">
													<div class="modal__title">
														Вы уверены что хотите разблокировать пользователя
													</div>
												</div>

												<div class="modal__content">
													<div class="modal__form">
														<div class="modal__form--message message__center">
															Вы успешно разблокировали пользователя
														</div>
													</div>
												</div>
											</div>
										</div>
									</modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { api } from '@/boot/axios'
import Multiselect from 'vue-multiselect'

export default {
	components: {
		Multiselect,
	},
	props: {
		user: {
			type: Object,
		},
	},
	data() {
		return {
			applications: null,
			totalItems: null,
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
				roles: {
					id: 'all',
					description: 'Все',
				},
				region: {
					id: 'all',
					name: 'Все',
				},
				status: this.$t('orders.userList'),
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			roleList: null,

			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id', width: '80px' },
				{ text: this.$t('headers.fio'), value: 'name' },
				{ text: this.$t('headers.register_at'), value: 'created_at', width: '200px' },
				{ text: this.$t('headers.role'), value: 'role_id' },
				{ text: this.$t('headers.regions'), value: 'regions_info' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	computed: {
		...mapGetters(['_getRegionList']),
		filteredlist() {
			return (
				this.applications?.filter((d) => {
					let full_name = d.first_name + ' ' + d.last_name + ' ' + d.middle_name
					if (
						((d.first_name
							? d.first_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) &&
							(d.last_name
								? d.last_name.toLowerCase().includes(this.filters.search.toLowerCase())
								: true) &&
							(d.middle_name
								? d.middle_name.toLowerCase().includes(this.filters.search.toLowerCase())
								: true)) ||
						(full_name
							? full_name.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) ||
						(d.iin_bin
							? d.iin_bin.toLowerCase().includes(this.filters.search.toLowerCase())
							: true) ||
						(d.email ? d.email.toLowerCase().includes(this.filters.search.toLowerCase()) : true) ||
						(this.$options.filters.formatDate(d.created_at)
							? this.$options.filters
									.formatDate(d.created_at)
									.toLowerCase()
									.includes(this.filters.search.toLowerCase())
							: true)
					) {
						return true
					}
				}) || []
			)
		},
		regionList() {
			return [
				{
					id: 'all',
					name: 'Все',
				},
				...this._getRegionList,
			]
		},
	},
	watch: {
		filters: {
			handler() {
				this.apiGetUserList(1, this.options.itemsPerPage)
			},
			deep: true,
		},
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		activeElement(id) {
			api
				.post('user/' + id + '/activate', {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$modal.show('modal_success')
						this.apiGetUserList()
						// this.$router.push({path: '/'+ this.$i18n.locale +'/account/users'});
					}
				})
		},
		blockElement(evt) {
			const formData = new FormData(evt.target)
			api
				.post('user/' + this.blockElemet.id + '/delete', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.blockElemet = null
						this.$modal.hide('modal_delete')
						this.apiGetUserList()
					}
				})
		},
		pagination(options) {
			this.apiGetUserList(options.page, options.itemsPerPage)
		},
		apiGetUserList(page = 1, per_page = 10) {
			const params = {
				page: page,
				per_page: per_page,
			}
			if (this.filters.roles?.id !== undefined && this.filters.roles?.id !== 'all')
				params['filters[role_id]'] = this.filters.roles?.id
			if (this.filters.region?.id !== undefined && this.filters.region?.id !== 'all')
				params['filters[region_id]'] = this.filters.region?.id
			if (this.filters.status?.status !== undefined && this.filters.status?.status !== 'all')
				params['filters[status]'] = this.filters.status?.status
			api
				.get('user/list', {
					params,
				})
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
		apiGetRolesList() {
			api
				.get('user/role/list')
				.then((response) => {
					if (response.data) {
						this.roleList = response.data
						this.roleList.unshift({
							id: 'all',
							description: 'Все',
						})
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	beforeMount() {
		this.apiGetRolesList()
		this._fetchRegionList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
<style>
header .header__row {
	display: flex;
}
</style>
